<template lang="pug">
div.settings-change
  div.settings-change__settings-panel.panel
    v-icon.settings-change__settings-panel-close.icon(
      v-if='showCloseIcon'
      icon='XIcon'
      size='24'
      @click='closeSettingsModal'
    )
    div.settings-change__settings-panel-title Настройка камеры и микрофона
    video.settings-change__preview(
      ref='previewVideo'
      muted
      autoplay
      playsinline
      poster='@/images/account_circle.png'
    )

    div.settings-change__settings-panel-camera(v-if='videoDevices.length')
      div.settings-change__select-title Камера
      div.settings-change__select-container
        select.settings-change__select(
          v-model='selectedDevicesId.currentVideoId'
          @change='setPreviewVideo'
        )
          template(v-for='item in videoDevices')
            option(:value='item.deviceId') {{ item.label }}

    div.settings-change__settings-panel-mic(v-if='audioDevices.length')
      div.settings-change__select-title Микрофон
      div.settings-change__select-container
        select.settings-change__select(
          v-model='selectedDevicesId.currentAudioId'
        )
          template(v-for='item in audioDevices')
            option(:value='item.deviceId') {{ item.label }}
    div.settings-change__settings-panel-button
      v-button(@click.native='onDeviceChange' block) {{ isModal ? 'Сохранить' : 'Войти на мероприятие' }}
</template>

<script>
import Vue from 'vue'
import VButton from '../components/v-button.vue'
import router from '../router'
import { mapGetters, mapActions } from 'vuex'
import VIDEO from '@/constants/video'

export default {
  name: 'VSettingsChange',
  components: {
    VButton,
  },
  props: {
    isModal: { type: Boolean, default: false },
    showCloseIcon: { type: Boolean, default: true },
  },
  data: () => ({
    selectedDevicesId: {
      currentAudioId: null,
      currentVideoId: null,
    },
  }),
  computed: {
    ...mapGetters('devices', [
      'getCurrentDevicesId',
      'getAudioDevices',
      'getVideoDevices',
    ]),
    audioDevices: {
      get() {
        return this.getAudioDevices
      },
    },
    videoDevices: {
      get() {
        return this.getVideoDevices
      },
    },
  },
  async created() {
    await this.getDevices()
    await this.getAccessToDevices()
    this.getDevices()
    this.setPreviewVideo()
  },
  methods: {
    ...mapActions('devices', [
      'requestDevices',
      'getAccessToDevices',
      'setCurrentDevicesId',
    ]),
    closeSettingsModal() {
      this.$store.commit('UPDATE_MODAL_SETTINGS_VISIBLE', false)
    },
    async onDeviceChange() {
      if (!this.$store.state.audioContext) {
        const AudioContext = window.AudioContext || window.webkitAudioContext
        const audioContext = new AudioContext()
        this.$store.commit('UPDATE_AUDIO_CONTEXT', audioContext)
      }

      this.$store.commit('UPDATE_MODAL_SETTINGS_VISIBLE', false)

      this.setCurrentDevicesId(this.selectedDevicesId)

      setTimeout(() => {
        this.getAccessToDevices()
      }, 0)

      this.$emit('on-settings-change')

      if (!this.isModal) {
        router.push({
          path: '/',
          query: {
            workspace: this.$store.state.query.workspace,
            key: this.$store.state.query.key,
          },
        })
      }
    },
    async getDevices() {
      return this.requestDevices().then(() => {
        const currentDevicesId = this.getCurrentDevicesId

        if (
          !currentDevicesId.currentAudioId &&
          !currentDevicesId.currentVideoId
        ) {
          this.setCurrentDevicesId({
            currentAudioId: this.audioDevices[0]?.deviceId,
            currentVideoId: this.videoDevices[0]?.deviceId,
          })

          this.selectedDevicesId = {
            currentAudioId: this.audioDevices[0]?.deviceId,
            currentVideoId: this.videoDevices[0]?.deviceId,
          }
        } else {
          this.selectedDevicesId = { ...currentDevicesId }
        }
      })
    },
    setPreviewVideo() {
      const constraints = {
        audio: false,
        video: {
          deviceId: this.selectedDevicesId.currentVideoId,
          ...(Vue.prototype.$isMobile && window.innerHeight > window.innerWidth
            ? {
                width: { ideal: VIDEO.IDEAL_WIDTH_MOBILE },
                height: { ideal: VIDEO.IDEAL_HEIGHT_MOBILE },
                aspectRatio: { ideal: VIDEO.ASPECT_RATIO_MOBILE },
              }
            : {
                width: { ideal: VIDEO.IDEAL_WIDTH },
                height: { ideal: VIDEO.IDEAL_HEIGHT },
                aspectRatio: { ideal: VIDEO.ASPECT_RATIO },
              }),
        },
      }
      navigator.mediaDevices
        .getUserMedia(constraints)
        .then((stream) => {
          this.$refs.previewVideo.srcObject = stream
          this.$refs.previewVideo.play()
        })
        .catch((e) => {
          console.log('get user media error: ', e)
        })
    },
  },
}
</script>

<style lang="scss">
.settings-change {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);

  &__settings-panel-close {
    position: absolute;
    top: 15px;
    right: 15px;
    height: 34px;
    width: 34px;
    cursor: pointer;
  }

  &__settings-panel {
    position: relative;
    // height: 400px;
    width: 650px;
    padding: 52px 62px;

    &-title {
      margin-bottom: 30px;
      font-weight: 500;
      font-size: 28px;
      text-align: center;
      color: #5e5873;
    }
  }

  &__settings-panel-camera {
    margin-bottom: 16px;
  }

  &__settings-panel-mic {
    width: 520px;
  }

  &__settings-panel-button {
    display: flex;
    justify-content: center;
    margin-top: 5px;
    padding: 10px 0;
    width: 100%;
  }

  &__select-title {
    margin-bottom: 6px;
    font-size: 12px;
    color: #6e6b7b;
  }

  &__select-container {
    position: relative;
  }

  &__select {
    appearance: none;
    position: relative;
    display: block;
    width: 100%;
    padding: 7px 12px;
    font-size: 12px;
    color: #b9b9c3;
    border: 1px solid #d8d6de;
    border-radius: $border-radius;
  }

  &__preview {
    width: 100%;
    border-radius: $border-radius;
    margin-bottom: 10px;
  }

  @media (max-width: 800px) {
    &__settings-panel {
      width: 90%;
      padding: 20px 30px;

      &-title {
        font-size: 18px;
        margin-bottom: 15px;
      }
    }

    &__settings-panel-mic {
      width: 100%;
    }
    &__preview {
      max-height: 35vh;
    }
  }
}
</style>
